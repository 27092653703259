import { render, staticRenderFns } from "./moban.vue?vue&type=template&id=6d9939de&scoped=true&"
import script from "./moban.vue?vue&type=script&lang=js&"
export * from "./moban.vue?vue&type=script&lang=js&"
import style0 from "./moban.vue?vue&type=style&index=0&id=6d9939de&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d9939de",
  null
  
)

export default component.exports