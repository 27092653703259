/*
 * @Author: WYY
 * @Description:判断题
 * @ModuleName: 判断题
 * @Date: 2018-09-25 11:47:46
 * @Last Modified by: WYY
 * @Last Modified time: 2018-11-26 18:21:57
 */
<template>
    <div class="T_box">
        <div class="T_title">
            Your Location：{{this.Target.title}}
        </div>
        <div class="U_title">
            <h1 class="u_tt" v-if="!RightLeft">
                {{this.Target.catalog}}
            </h1>
            <div class="Section_sel" v-else>
                <i class="el-icon-arrow-left" @click='PartPrev()'></i>
                <h1 class="u_tt">{{this.Target.catalog}}</h1>
                <i class="el-icon-arrow-right" @click="PartNext()"></i>
            </div>
            <div class="describe" @click="pshow=true">
                <img src="../../assets/describe.png" alt="">
                <span>
                    Directions
                </span>
            </div>
        </div>
        <div id="myElement" style="display:none !important;">Loading the player ...</div>
        <div class="T_content">
            <div class="Unit_d">
                <div class="Unit_con">
                    <!-- <div class="ShuiYinRight"> 中国民用航空西北地区空中交通管理局 </div> -->
                    <!-- <div class="ShuiYinLeft">{{Username}}</div> -->
                    <div class="Audio_play">
                        <span class="t1"> Stem </span>
                        <img src="../../assets/icon_horn.png" v-if="InPlay" alt="" class="t2" @click="player.pause();InPlay=false;">
                        <img src="../../assets/biglaba.png" v-else alt="" class="t2" @click="player.play();InPlay=true;">
                        <el-slider v-model="audioTime" :format-tooltip="formatTooltip" :disabled='!GetTime' @change='TimeChange()'></el-slider>
                        <span class="t3"> {{playertime}} </span>
                        <img src="../../assets/repeat.png" style="cursor:pointer;" alt="" @click="ResetTime()" v-if="Pattern!='测试模式'">
                    </div>
                    <div class="Question_list">
                        <span class="t1">Question. {{childQuestionListIndex + 1}}/{{childQuestionListAll}}</span>
                        <img src="../../assets/xiaolaba.png" class="t2" alt="">
                    </div>
                    <div class="Question_box clearfix">
                        <div :class="['question_des',(mshow.text!='2'&&mshow.notes!='2'&&mshow.key!='2')?'active':'']">
                            <!-- <div class="title">True or False</div> -->
                            <div class='content_t'>

                            </div>
                        </div>

                        <div class="Text" v-show="mshow.text==2">
                            <div class="title">Text <i class="el-icon-close right" @click="threeShowClose"></i></div>
                            <div class="t_content" v-html="this.questionData.questionText"></div>
                        </div>
                        <div class="Notes" v-show="mshow.notes==2">
                            <div class="title"> Notes  <i class="el-icon-close right" @click="threeShowClose"></i> </div>
                            <div class="t_content">
                                <textarea name="" v-model="NoteItem.content" @focus="NoteFocus()" @blur="NoteBlur()" maxlength="500" id="" cols="30" rows="10"></textarea>
                                <img v-if="NoteItem.content.length<=0" src="../../assets/notesbc.png" class="bc" alt="">
                                <button class="save" @click="SaveNotes"> Save </button>
                            </div>
                        </div>
                        <!-- key -->
                        <div class="Text" v-show="mshow.key==2">
                            <div class="title">Key <i class="el-icon-close right" @click="threeShowClose"></i></div>
                            <div class="t_content" v-html="this.questionData.questionText"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="T_option">
            <div class="Unit_option">
                <div class="btn_list">
                    <span class="btn" @click="T_back(childQuestionListIndex,childQuestionListAll)"></span><span class="btn2" @click="T_next(childQuestionListIndex,childQuestionListAll)"></span>
                </div>
                <div class="right">
                    <img src="../../assets/Clock.png" alt="" v-if="this.usertype==0">
                    <span style="margin-right:30px;" v-if="this.usertype==0">
                        {{this.RemainingTime}}
                    </span>
                    <div @click='SignOut()' v-if="Pattern!='测试模式'">
                        <img src="../../assets/exiticon.png" alt="">
                        <span>Quit Course</span>
                    </div>
                </div>
            </div>
        </div>


        <div class="Machine">
            <img v-if="mshow.text==0" src="../../assets/TEXT3.png" alt="" style="cursor:not-allowed;">
            <img v-if="mshow.text==1" src="../../assets/1TEXT.png" alt="" @click="Clicktext">
            <img v-if="mshow.text==2" src="../../assets/2TEXT.png" alt=""  @click="mshow.text=1">
            <img v-if="mshow.notes==0" src="../../assets/NOTES3.png" alt=""  style="cursor:not-allowed;">
            <img v-if="mshow.notes==1" src="../../assets/1NOTES.png" alt=""  @click="Clicknotes">
            <img v-if="mshow.notes==2" src="../../assets/2NOTES.png" alt=""  @click="mshow.notes=1">
            <img v-if="mshow.key==0" src="../../assets/KEY3.png" alt=""  style="cursor:not-allowed;">
            <img v-if="mshow.key==1" src="../../assets/1KEY.png" alt="" @click="mshow.key=2;mshow.notes=1;mshow.text=1;">
            <img v-if="mshow.key==2" src="../../assets/2KEY.png" alt="" @click="mshow.key=1">
        </div>

        <popup :show.sync="pshow" :describe='this.Target.description'></popup>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                // 机器人按钮显示
                mshow:{
                    text:1,
                    notes:1,
                    key:1,
                    // 0 不可选 1 可选 2 已选
                },
                // 数据加载 判断 防止二次加载
                Panduan:true,
                // 章节信息
                Target:{
                    catalog:'',
                    description:'',
                    isEnd:null,
                    title:'',
                },
                // 左右 切换按钮显示
                RightLeft:false,
                // 描述 弹窗显示
                pshow:false,

                // 问题 数据
                questionData:{},
                questionIndex:0,
                questionAll:0,
                childQuestionListIndex:0, // 当前子题
                childQuestionListAll:0, // 所有子题 长度

                // 播放器 参数  命名 不要重复   mixin 中使用了 参数
                GetTime:false, // 获取 视频总时长
                audioTime:0, // 滑块 value
                player:null, // 播放器
                playertime:'', //总时长
                InPlay:false, // 判断是否 播放中
                // 播放器 参数

                check:'', // 判断是否选择 答案
                complate:false, // 是否提交答案
                answer:'', // 答案

                NoteItem:{//笔记
                    id:'',
                    content:'',
                },
                // 绑题规则
                BindRole:{},

                // 树形 上层数据
                UpLayerData:{},
            }
        },
        created() {
            if(this.menulist.length>0){
                this.Panduan = true;
                this.getinfo();
            }else{
                this.Panduan = false;
            }
        },
        mounted(){

        },
        computed:{
            datacomplate:function(){
                return this.$store.state.common.datacomplate;
            },
            dataLayer:function(){
                return this.$store.state.common.dataLayer;
            },
        },
        watch:{
            datacomplate:function(v){
                if(!this.Panduan){
                    this.getinfo();
                }
            },
            dataLayer:function(){
                this.getinfo();
            },
        },
        methods:{
            async getinfo(){
                let data = this.GetTargetItem(); // mixin 调用方法
                if(!!data.last){
                    this.Target = data.data[data.last];
                }else{
                    this.Target = data.data;
                }
                this.UpLayerData = data.updata;
                this.Target.title = data.title;
                // 判断 绑题规则 设置 notes key txt
                this.BindRole = this.Target.bindRole;
                if(this.BindRole.isRecordNote == 1) this.mshow.notes = 1;
                else this.mshow.notes = 0;

                if(this.BindRole.isReadAnswer == 1) this.mshow.key = 1;
                else this.mshow.key = 0;

                if(this.BindRole.isReadOriginal  == 1) this.mshow.text = 1;
                else this.mshow.text = 0;
                // 判断 绑题规则 设置 notes key txt
                if(this.Target.isEnd == 1 && data.data.length>1){
                    this.RightLeft = true;
                }else{
                    this.RightLeft = false;
                }
                // 设置 模式
                if(this.Target.isEnd == 1){
                    sessionStorage.setItem('Pattern',this.Target.bindRole.ruleTypeName);
                    this.$store.commit('setitem',{'name':'Pattern','item':this.Target.bindRole.ruleTypeName})
                }

                let qIndex = sessionStorage.getItem('questionIndex') || 0; // 问题下标
                this.questionIndex = sessionStorage.getItem('SonquestionIndex') || 0; // 问题子题下标
                this.questionAll = this.Target.questionList.length; // 问题 总数
                let questiondata = await this.GetQuestion(this.Target.questionList[qIndex].oldId,this.Target.sectionMain.id);
                this.questionData = questiondata.data;  // 当前问题 数据
                this.childQuestionListAll = this.questionData.childQuestionList.length;// 当前问题 子题总数

                // 获取 笔记信息
                let notes = "/CourseNote/getNoteByQuestionId?id=" + this.questionData.childQuestionList[0].id;
                let notes_ = await this.Tongyong(notes);
                if(!!notes_.data&&notes_.data.length>0){
                    this.NoteItem = notes_.data[0];
                }else{
                    this.NoteItem.content = '';
                }
                this.FlashPlay();
            },

            // 答案 确认
            async confirm(){
                if(!!!this.check){
                    this.$message.warning('请选择!');
                }else{
                    let url = "/question/getAnswerById?questionId=" + this.questionData.childQuestionList[this.childQuestionListIndex].id;
                    let url_;
                    try{
                        url_ = await this.$ajax.get(url);
                    }catch(e){
                        this.$message.error('获取数据失败!');
                    }
                    this.answer = url_.data.answers;
                    this.complate = true;
                }
            },
            // 保存 笔记
            async SaveNotes(){
                let question = this.questionData.childQuestionList[this.childQuestionListIndex];
                let item = this.NoteItem;
                if (item.content !== null && item.content !== undefined) {
                    item.content = item.content.replace(/(^\s*)|(\s*$)/g, '');
                }
                if(!!!item.content){
                    this.$message.warning('请填写笔记!');
                    return false;
                }

                let layer = sessionStorage.getItem('targetceng').split(',');
                layer = layer.map(el=>{
                    el = el.split('.');
                    return el;
                })
                let data = {
                    courseId:this.menulist[layer[0][1]].courceId,
                    nodeId:this.Target.id,
                    title:'',
                    content:item.content,
                    questionId:question.id,
                    levelName:this.Target.catalog,
                }
                if(!!item.id){
                    let edit = '/CourseNote/updateCourseNote?id=' + item.id + "&content=" + item.content;
                    let edit_ = await this.Tongyong(edit);
                    if(edit_.code == '200'){
                        this.$message.success('修改笔记成功!');
                    }
                }else{
                    let add = '/CourseNote/addCourseAdvice';
                    let add_ = await this.Tongyong({'url':add,'type':'post','str':data});
                    if(add_.code == '200'){
                        this.$message.success('添加笔记成功!');
                        this.NoteItem.id = add_.data.id;
                    }
                }

            },
            //  滑块 tooltip
            formatTooltip(val){
                if(!!this.player){
                    let c = parseFloat((val / 100) * this.player.getDuration()).toFixed(2);
                    if(c!=NaN&&c!='NaN') c += 's'
                    else c = 's'
                    return c;
                }else{
                    return '';
                }
            },
            // 重新 播放
            ResetTime(){
                this.audioTime = 0;
                this.player.setVolume(100);
                this.player.seek(0);
                this.InPlay = true;
            },
            // 滑块  播放时段改变
            TimeChange(){
                let time = (this.audioTime / 100) *　this.player.getDuration();
                this.player.seek(time);
                this.InPlay = true;
            },

            // flash 播放器 初始化
            FlashPlay(data){
                this.player = jwplayer('myElement');
                let item = {
                    'autostart': true,
                    'flashplayer': process.env.BASE_URL + "jwplayer/jwplayer.flash.swf",
                    'file': 'rtmp://184.72.239.149/vod/&mp4:BigBuckBunny_115k.mov',
                    'streamer': '',
                    'aspectratio': '10:6',
                    dock: false,
                }
                this.player.setup(item);
                this.player.onBuffer(()=>{
                    console.log(1);
                })
                this.player.onBufferFull(()=>{
                    console.log('加载完成!');
                    this.player.setVolume(0);
                })
                this.player.onTime(()=>{
                    if(!this.GetTime){
                        let time = this.player.getDuration();
                        if(time>0){
                            this.player.setVolume(100);
                            this.playertime = window.ComputedTime(time);
                            if(this.BindRole.isAutoPaly == 1){
                                this.player.seek(0);
                            }else{
                                this.player.seek(0);
                                this.player.pause();
                            }
                            this.GetTime = true;
                        }
                    }else{
                        this.InPlay = true;
                        let c = (this.player.getPosition() / this.player.getDuration()) * 100;
                        this.audioTime = c;
                    }
                })
                this.player.onComplete(()=>{
                    this.InPlay = false;
                })
            }
        },
        destroyed(){
            // 删除 播放器
            if(!!this.player){
                this.player.remove();
            }

        },
    }

</script>

<style lang="less" scoped>
    .Unit_con{
        padding: 25px 30px 40px 30px;
    }
</style>


